import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';
import { KeycloakAuthorizationService } from '../keycloak-authz-angular/core/services/keycloak-authorization.service';

export function initializer(keycloak: KeycloakService, authService: KeycloakAuthorizationService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: environment.keycloak,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: []
        });
        await authService.init({
          config: environment.keycloak,
          initOptions: {
            defaultResourceServerId: 'ecotx-permissions'
          }
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}
