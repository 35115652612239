import { NgModule } from "@angular/core";
import { ApolloClientOptions, InMemoryCache, from } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import { environment } from "../environments/environment";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function createDefaultApollo(
  httpLink: HttpLink
): ApolloClientOptions<any> {
  return {
    link: from([
      httpLink.create({
        uri: `${environment.apiUrl}/graphql`,
        withCredentials: true,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      errorLink,
    ]),
    cache: new InMemoryCache(),
  };
}

export function createNamedApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    api: {
      name: "api",
      link: from([
        httpLink.create({
          uri: `${environment.apiUrl}/graphql`,
          withCredentials: true,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
        errorLink,
      ]),
      cache: new InMemoryCache(),
    },
    apiGraphql: {
      name: "apiGraphqlUrl",
      link: from([
        httpLink.create({
          uri: `${environment.apiGraphqlUrl}`,
          withCredentials: true,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
        errorLink,
      ]),
      cache: new InMemoryCache(),
    },
    userprofile: {
      name: "userprofile",
      link: from([
        httpLink.create({
          uri: `${environment.userProfileApiUrl}/graphql`,
          withCredentials: true,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
        errorLink,
      ]),
      cache: new InMemoryCache(),
    },
    mapservices: {
      name: "mapservices",
      link: from([
        httpLink.create({
          uri: `${environment.mapServicesUrl}/graphql`,
          withCredentials: true,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
        errorLink,
      ]),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      deps: [HttpLink],
      useFactory: createDefaultApollo,
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createNamedApollo,
    },
  ],
})
export class GraphQLModule {}
