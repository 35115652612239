import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./modules/auth/auth.guard";

export const routes: Routes = [
  {
    path: "error",
    loadChildren: () =>
      import("./modules/errors/errors.module").then((m) => m.ErrorsModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    data: {
      permissions: [
        {
          rsname: "backoffice-menu",
          scope: "view",
        },
      ],
    },
    loadChildren: () =>
      import("./pages/layout.module").then((m) => m.LayoutModule),
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
