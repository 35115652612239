// USA
export const locale = {
  lang: "en",
  data: {
    TRANSLATOR: {
      SELECT: "Select your language",
    },
    USER_NOTIFICATION: {
      IMPORT_IN_PROGRESS: "Import in progress",
      IMPORT_COMPLETED: "Import completed",
      FAILED_IMPORT: "Failed import",
      NEW_NOTIFICATIONS: "New",
      ALERTS: "Alerts",
      EVENTS: "Events",
      ALL_CAUGHT_UP: "All caught up!",
      NO_NEW_NOTIFICATIONS: "No new notifications.",
      CLEAR_ALL: "Clear all"
    },
    VALIDATION: {
      WAS_ENTERED_CORRECT: " was entered correct.",
      WAS_ENTERED_CORRECT_FEMININE: " was entered correct.",
      IS_REQUIRED: " is required.",
      IS_REQUIRED_FEMININE: " is required.",
      SHOULD_HAVE_AT_LEAST_1_SYMBOL: " should have at least 1 symbol.",
      SHOULD_HAVE_AT_LEAST_3_SYMBOLS: " should have at least 3 symbols.",
      SHOULD_HAVE_MAXIMUM_100_SYMBOLS: " should have maximum 100 symbols.",
      SHOULD_HAVE_A_MINIMUM_VALUE_OF_ONE:
        " should have a minimum value of one.",
    },
    KEYWORDS: {
      CONFIRM: "Confirm",
      PER_INTERVAL: "Per interval",
      CLASS: "Classes",
      UNIQUE: "Unique",
      NOT_DEFINED: "Not Defined",
      TYPE: "Type",
      CONCLUDE: "Conclude",
      ADD: "Add",
      REMOVE: "Remove",
      ALL: "All",
      NONE: "None",
      ID: "ID",
      EDIT: "Edit",
      CREATE: "Create",
      SAVE: "Save",
      CANCEL: "Cancel",
      DELETE: "Delete",
      BACK: "Back",
      RESET: "Reset",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      INACTIVE: "Inactive",
      FETCH: "Fetch",
      UPDATE_STATUS: "Update status",
      UPDATE_CONFIG:"Update status",
      ENABLED: "Enabled",
      DISABLED: "Disabled",
      EXPIRED: "Expired",
      UNLOCKED: "Unlocked",
      LOCKED: "Locked",
      FILTER_BY_STATUS: "Filter by status",
      BY_STATUS: " by status",
      FILTER_BY_TYPE: "Filter by type",
      SEARCH_IN_ALL_FIELDS: "Search in all fields",
      FILTER: "Filter",
      BY_TYPE: " by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: " in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      DELETE_SELECTED: "Delete selected",
      FETCH_SELECTED: "Fetch selected",
      ACTIONS: "Actions",
      CREATED: "Creation Date",
      VERSION: "Version",
      DISABLE: "Disable",
      ENABLE: "Enable",
      UPDATED: "Last Update Date",
      MAP: "Map",
      SELECT: "Select ",
      SELECT_FEMININE: "Select",
      NEW: "New ",
      NEW_FEMININE: "New ",
      NAME: "Name",
      USERNAME: "User name",
      DISPLAY_NAME: "Display name",
      DESCRIPTION: "Description",
      LAYER: "Layer",
      USER: "User",
      DISABLE_ALL: "Disable all",
      VIEW: "View",
      CLOSE: "Close",
      CATEGORY: "Category",
      ORDER: "Order",
      DEFAULT: "Default",
      CUSTOM: "Custom",
      VERIFY: "Verify",
      UNVERIFY: "Unverify",
      VERIFIED: "Verified",
      UNVERIFIED: "Unverified",
      DOWNLOAD: "Download",
    },
    FILE_MANAGEMENT: {
      RENAME: "Rename",
      COPY_LINK: "Copy link",
      CLICK_OR: "Click or",
      DRAG_THE: "drag the",
      FILE_HERE: "file here",
      LABELS_PLACEHOLDER: "LAbels separated by ; Ex(ufv;eol;janeiro)",
      DISPOSAL_IN_DAYS: "Disposal In Days",
      LABELS: "Labels",
      CONTEXT: "Context",
      FILENAME: "File name",
      SIZE: "Tamanho",
      MODIFIED: "Modified",
      STATUS: "Status",
      DELETE_FILE: "Delete file",
      FILE_IS_BEING_DELETED: "File is being deleted",
      ARE_YOU_SURE_TO_DELETE_THIS_FILE:
        "Are you sure you want to delete this file?",
    },
    MENU: {
      DASHBOARD: "Dashboard",
      ECOMAP: "EcoMap",
      EMAP: "e-Map",
      EBI: "e-BI",
      LEADS: "Leads",
      BACKOFFICE: "Backoffice",
      PLATFORM: "Platform",
      QUICK_SEARCH: "Quick search",
      NOTIFICATION: "Notification",
      SUBHEADER: {
        TODAY: "Today",
      },
      USER_PROFILE: {
        BY_MONTH: "by month",
        UNIQUE_PAYMENT_OF: "Unique payment of",
        NEW_USER_ADDRESS: {
          EDIT: "Edit",
          CREATE: "Create",
          USER_ADDRESS: "User address",
          STREET: "Street",
          STREET_WAS_ENTERED_CORRECT: "Street was entered correct",
          STREET_IS_REQUIRED: "Street is required",
          STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Street should have at least 3 symbols",
          STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Street should have maximum 100 symbols",
          NUMBER: "Number",
          NUMBER_WAS_ENTERED_CORRECT: "Number was entered correct",
          NUMBER_IS_REQUIRED: "Number is required",
          NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Number should have at least 3 symbols",
          NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Number should have maximum 100 symbols",
          COMPLEMENT: "Complement",
          COMPLEMENT_WAS_ENTERED_CORRECT: "Complement was entered correct",
          COMPLEMENT_IS_REQUIRED: "Complement is required",
          COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Complement should have at least 3 symbols",
          COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Complement should have maximum 100 symbols",
          NEIGHBORHOOD: "Neighborhood",
          NEIGHBORHOOD_WAS_ENTERED_CORRECT: "Neighborhood was entered correct",
          NEIGHBORHOOD_IS_REQUIRED: "Neighborhood is required",
          NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Neighborhood should have at least 3 symbols",
          NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Neighborhood should have maximum 100 symbols",
          POSTAL_CODE: "Postal code",
          POSTAL_CODE_WAS_ENTERED_CORRECT: "Postal code was entered correct",
          POSTAL_CODE_IS_REQUIRED: "Postal code is required",
          POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Postal code should have at least 3 symbols",
          POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Postal code should have maximum 100 symbols",
          SELECT_STATE: "Select state",
          SELECT_STATE_TIP: "Please select a state ",
          STATE: "State",
          SELECT_CITY: "Select city",
          SELECT_CITY_TIP: "Please select a city",
          CITY: "City",
          CANCEL: "Cancel",
          SAVE: "Save",
        },

        NUMBER: "Number",
        DATE: " Date",
        MY_REQUESTS: "My requests",
        CARD_NUMBER: "Card Number",
        CARD_EXPIRATION_DATE: "Expiration date",
        CARD_NAME: "Card Name",
        USER_ADDRESS: "User address",
        CHAT: "Chat",
        FOLLOW: "Follow",
        EMAIL: "Email",
        CREDIT_CARDS_LIST: "Credit card list",
        NEW_CREDIT_CARD: "New credit card",
        PHONE: "Phone",
        PROFILE_OVERVIEW: "Profile overview",
        PERSONAL_INFORMATION: "Personal information",
        CHANGE_PASSWORDS: "Change password",
        EMAIL_SETTINGS: "Email settings",
        CREDIT_CARD: "Credit card",
        ADDRESS: "User addresses",
        LOGIN_ACTIVITY: "Login activity",
        DESCRIPTION_ACTIVITY: "Description activity",
        SAVED_CREDIT_CARD: "Saved credit cards",
        PERSONAL_INFO: "Personal Info",
        UPDATE_PERSONAL_INFO: "Update personal info",
        SAVE_CHANGES: "Save changes",
        CANCEL: "Cancel",
        CUSTOMER_INFO: "Customer info",
        AVATAR: "Avatar",
        AVATAR_TIP: "Allowed file types: png, jpg, jpeg.",
        REAL_NAME: "Name",
        LAST_NAME: "First name",
        COMPANY_NAME: "Company name",
        COMPANY_NAME_TIP:
          "If you want your invoices addressed to a company. Leave blank to use your full name.",
        CONTACT_INFO: "Contact info",
        CONTACT_PHONE: "Contact phone",
        CONTACT_PHONE_TIP: "We will never share your phone with anyone else",
        EMAIL_ADDRESS: "Email address",
        COMPANY_SITE: "Company site",

        CHANGE_PASSWORD: "Change password",
        CHANGE_YOUR_ACCOUNT_PASSWORD: "Change your account password",
        CURRENT_PASSWORD: "Current password",
        CURRENT_PASSWORD_WAS_ENTERED_CORRECT:
          "Current password was entered correct",
        CURRENT_PASSWORD_IS_REQUIRED: "Current password is required",
        NEW_PASSWORD: "New password",
        VERIFY_PASSWORD: "Verify password",
        PASSWORD_CONFIRMATION_WAS_ENTERED_CORRECT:
          "Password confirmation was entered correct",
        PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
          "'Password' and 'Confirm password' didn't match",

        EMAIL_SETTINGS_2: "Email settings",
        EMAIL_SETTINGS_TIP: "Update your email settings",
        SETUP_EMAIL_NOTIFICATION: "Configure your email Notifications",
        EMAIL_NOTIFICATION: "Email notification",
        SEND_COPY_TO_PERSONAL_EMAIL: "Send copy to personal email",
        ACTIVITY_RELATED_EMAILS: "Activity related emails",
        WHEN_TO_EMAIL: "When to email",
        YOU_HAVE_NEW_NOTIFICATIONS: "You have new notifications",
        YOU_ARE_SENT_A_DIRECT_MESSAGE: "You are sent a direct message",
        SOMEONE_ADDS_YOU_AS_A_CONNECTION: "Someone adds you as a connection",
        WHEN_TO_ESCALATE_EMAIL: "When to escalate email",
        UPON_NEW_ORDER: "Upon new order",
        NEW_MEMBERSHIP_APPROVAL: "New membership approval",
        MEMBER_REGISTRATION: "Member registration",

        UPDATES_FROM_KEENTHEMES: "Updates From Keenthemes",
        EMAIL_YOU_WITH: "Email you with",
        NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
          "News about Keenthemes products and feature updates",
        TIPS_ON_GETTING_MORE_OUT_OF_KEEN: "Tips on getting more out of Keen",
        THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
          "Things you missed since you last logged into Keen",
        NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
          "Tips on Metronic business products",
        TIPS_ON_METRONIC_BUSINESS_PRODUCTS:
          "Tips on Metronic business products",

        NEW_ADDRESS: "New address",
        ADDRESS_1: "Address 1",
        PRIMARY: "Primary",
        DELETE: "Delete",
        EDIT: "Edit",

        PAYMENTS_LIST: "Payments list",
        PAYMENTS: "Payments",
        VIEW_PAYMENTS: "View payments",
        SUBSCRIPTION_NAME: "Subscription name",
        STATUS: "Status",
        PRICE: "Price",
        TYPE: "Type",
        CREATED: "Created",
        COUPON_CODE_NAME: "Coupon code name",
        PAID_INSTALMENTS: "Paid instalments",
        TOTAL_INSTALMENTS: "Total instalments",
        BOLETO_URL: "Boleto URL",
        BOLETO_BAR_CODE: "Boleto bar code",
        CREDIT_CARD_HASH: "Credit card hash",
        ACTION: "Action",
        CLOSE: "Close",
      },
      USER: {
        MESSAGE: "Message",
        MY_PROFILE: "My profile",
        DESCRIPTION_PROFILE: "Description profile",
        UPGRADE_PROFILE: "Upgrade profile",
        MY_MESSAGES: "My messages",
        DESCRIPTION_MESSAGES: "Description messages",
        MY_ACTIVITIES: "My activities",
        DESCRIPTION_ACTIVITIES: "Description activities",
        MY_TASKS: "My tasks",
        DESCRIPTION_TASKS: "Description tasks",
        SIGN_OUT: "Sign out",
        UPGRADE_PLAN: "Upgrade plan",
      },
    },
    SETTINGS: {
      DESCRIPTION:
        'Layout Builder serves to help define and configure as preferred design layout specifications and visualize it in real time. As layout configuration options will be saved until you change or reset as. To use the layout builder, choose as layout options and click the "Preview" button to preview the changes.',
      BUTTON_PREVIEW: "Preview",
      BUTTON_SAVE: "Save",
      BUTTON_RESET: "Reset",
      VALUES: {
        FIXED: "Fixed",
        FLUID: "Fluid",
        DEFAULT: "Default",
        TAB: "Tab",
        TRANSPARENT: "Transparent",
        SOLID: "Solid",
        SUBHEADER_1: "Subheader 1",
        SUBHEADER_POWERBI: "Subheader Power-BI",
        SUBHEADER_3: "Subheader 3",
        SUBHEADER_4: "Subheader 4",
        SUBHEADER_LEADS: "Subheader Leads",
        SUBHEADER_6: "Subheader 6",
      },
      SITE: {
        SITE: "Site",
        ENTER_NAME: "Name",
        ENTER_NAME_PLACEHOLDER: "Name",
        LOGO: "Logo",
        LOGO_TIP: "These are historical files of the type: png, jpg and jpeg.",
      },
      HEADER: {
        HEADER: "Header",
        DESKTOP_FIXED_HEADER: "Desktop fixed header",
        MOBILE_FIXED_HEADER: "Mobile fixed header",
        WIDTH: "Header width",
        WIDTH_TIP: "Select header width type",
        DISPLAY_HEADER_MENU: "Show header menu",
        STATIC_HEADER_MENU: "Display static header menu",
        EXHIBIT: "Exhibit",
        EXHIBIT_THE: "Exhibit",
        MENU_LAYOUT: "Layout of the header menu",
        SELECT: "Select the type of",
        MENU_ARROWS: "Header menu arrows",
        ALLOW: "Allow",
      },
      SUBHEADER: {
        SUBHEADER: "Subheader",
        DISPLAY_SUBHEADER: "Display subheader",
        DISPLAY_SUBHEADER_TIP: "Display subheader",
        FIXED_SUBHEADER: "Fixed subheader",
        FIXED_SUBHEADER_TIP:
          "Show Fixed Subheader. Requires 'Solid' Subheading style.",
        WIDTH: "Width",
        WIDTH_TIP: "Select the type of layout width",
        STYLE: "Subheader style",
        LAYOUT: "Subheader layout",
        SELECT_TIP: "Select",
      },
      CONTENT: {
        CONTENT: "Content",
        WIDTH: "Width",
        WIDTH_TIP: "Select layout width type",
      },
      ASIDE: {
        ASIDE: "Aside",
        DISPLAY: "Display",
        DISPLAY_TIP: "Show aside menu",
        STATIC_ASIDE_MENU: "Static aside menu",
        STATIC_ASIDE_MENU_TIP: "Show static aside menu",
        FIXED: "Fixed",
        FIXED_TIP: "Defined the fixed aside menu layout",
        MINIMIZE: "Minimize",
        MINIMIZE_TIP: "Allows the aside menu to minimize",
        DEFAULT_MINIMIZE: "Minimize pattern",
        DEFAULT_MINIMIZE_TIP: "Allows the aside menu minimize by default",
        SCROLL: "Scroll",
        SCROLL_TIP: "Allows scrolling of the aside menu",
        SUBMENU_TOGGLE_DROPDOWN: "Toggle drop-down list",
        SUBMENU_TOGGLE_DROPDOWN_TIP:
          "Allows toggle drop-down list (Only works when 'SCROLL' is disabled. *By default - mode is 'accordion').",
      },
      FOOTER: {
        FOOTER: "Footer",
        FIXED_DESKTOP_FOOTER: "Fixed desktop footer",
        FIXED_DESKTOP_FOOTER_TIP: "Define fixed footer on the desktop.",
        WIDTH: "Width",
        WIDTH_TIP: "Select the layout width type",
      },
      COLOR: {
        COLOR: "Color",
        WHITE_COLOR: "White color",
        PRIMARY_COLOR: "Primary color",
        SECONDARY_COLOR: "Secondary color",
        SUCCESS_COLOR: "Success color",
        INFO_COLOR: "Info color",
        WARNING_COLOR: "Warning color",
        DANGER_COLOR: "Danger color",
        LIGHT_COLOR: "Light color",
        DARK_COLOR: "Dark color",
        COLOR_TIP: "Select your color",
      },
    },
    ASIDE: {
      FILE_MANAGEMENT: "File management",
      FOLDERS: "Folders",
      ORDERS: "Orders",
      USER_COMPANY: "Users organizations",
      APPLY_STYLE: "Apply Style",
      USER_MANAGEMENT: "User Management",
      USERS: "Users",
      ROLES: "Roles",
      SITES: "Sites",
      LEVELS: "Levels",
      PLANS: "Plans",
      USER_MAP: "User Map",
      LOGINS: "Logins",
      USER_POWERBI: "e-BI",
      USER_POWERBI_TYPE: "e-BI Type",
      USER_ACCESS_MANAGEMENT: "User Access Management",
      COMPANIES: "Companies",
      USERS_ADDRESS: "Users Address",
      MAP_DATA_MANAGEMENT: "Map Data Management",
      BASE_MAP: "Base Map",
      LAYER: "Layers",
      GEOMETRY: "Style Dictionary",
      FILTER_EXPRESSION: "Filter Dictionary",
      MAP_LIST: "Maps",
      IMPORT_LAYER: "Layer Import Process",
      ORIENTED_SEARCH: "Search Map",
      PAYMENTS_MANAGEMENT: "Payments Management",
      COUPONS: "Discount Coupons",
      PAYMENTS: "Payments",
      SUBSCRIPTIONS: "Subscriptions",
      USER_PROFILE: "User Profile",
      FEED_MANAGEMENT: "Feed Management",
      TUTORIAL_MANAGEMENT: "Tutorial Management",
      EXPORT_MANAGEMENT: "Export Management",
      PRODUCTS: "Products",
    },
    LANGUAGES: {
      PORTUGUESE: "Portuguese",
      ENGLISH: "English",
      SPANISH: "Spanish",
      GERMAN: "German",
      FRENCH: "French",
      MANDARIN: "Mandarin",
      JAPANESE: "Japanese",
    },
    USER_PROFILE: {
      USER_PROFILE: "User profile",
      CHAT: "Chat",
      FOLLOW: "Follow",
      EMAIL: "Email",
      PHONE: "Phone",
      PROFILE_OVERVIEW: "Profile overview",
      LOGIN_ACTIVITY: "Login activity",
      DESCRIPTION_ACTIVITY: "Description activity",
      PERSONAL_INFO: "Personal information",
      UPDATE_PERSONAL_INFO: "Update personal info",
      SAVE_CHANGES: "Save changes",
      CANCEL: "Cancel",
      CUSTOMER_INFO: "Customer info",
      AVATAR: "Avatar",
      AVATAR_TIP: "Allowed file types: png, jpg, jpeg.",
      REAL_NAME: "Name",
      LAST_NAME: "First name",
      COMPANY_NAME: "Company name",
      COMPANY_NAME_TIP:
        "If you want your invoices addressed to a company. Leave blank to use your full name.",
      CONTACT_INFO: "Contact info",
      CONTACT_PHONE: "Contact phone",
      CONTACT_PHONE_TIP: "We will never share your phone with anyone else",
      EMAIL_ADDRESS: "Email address",
      COMPANY_SITE: "Company site",
      CHANGE_PASSWORD: "Change password",
      CHANGE_YOUR_ACCOUNT_PASSWORD: "Change your account password",
      CURRENT_PASSWORD: "Current password",
      NEW_PASSWORD: "New password",
      VERIFY_PASSWORD: "Verify password",
      PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
        "'Password' and 'Confirm password' didn't match",
      EMAIL_SETTINGS: "Email settings",
      EMAIL_SETTINGS_TIP: "Update your email settings",
      SETUP_EMAIL_NOTIFICATION: "Configure your email Notifications",
      EMAIL_NOTIFICATION: "Email notification",
      SEND_COPY_TO_PERSONAL_EMAIL: "Send copy to personal email",
      ACTIVITY_RELATED_EMAILS: "Activity related emails",
      WHEN_TO_EMAIL: "When to email",
      YOU_HAVE_NEW_NOTIFICATIONS: "You have new notifications",
      YOU_ARE_SENT_A_DIRECT_MESSAGE: "You are sent a direct message",
      SOMEONE_ADDS_YOU_AS_A_CONNECTION: "Someone adds you as a connection",
      WHEN_TO_ESCALATE_EMAIL: "When to escalate email",
      UPON_NEW_ORDER: "Upon new order",
      NEW_MEMBERSHIP_APPROVAL: "New membership approval",
      MEMBER_REGISTRATION: "Member registration",
      UPDATES_FROM_KEENTHEMES: "Updates From Keenthemes",
      EMAIL_YOU_WITH: "Enviar email com",
      NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
        "News about Keenthemes products and feature updates",
      TIPS_ON_GETTING_MORE_OUT_OF_KEEN: "Tips on getting more out of Keen",
      THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
        "Things you missed since you last logged into Keen",
      NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
        "Tips on Metronic business products",
      TIPS_ON_METRONIC_BUSINESS_PRODUCTS: "Tips on Metronic business products",
      CREDIT_CARD: "Credit card saved",
      USER_ADDRESS: "User Address",
      NEW_ADDRESS: "New address",
      ADDRESS_1: "Address 1",
      PRIMARY: "Primary",
      STREET: "Street",
      NUMBER: "Number",
      COMPLEMENT: "Complement",
      NEIGHBORHOOD: "Neighborhood",
      POSTAL_CODE: "Postal code",
      SELECT_STATE: "Select state",
      SELECT_STATE_TIP: "Please select state",
      SELECT_CITY: "Select city",
      SELECT_CITY_TIP: "Please select city",
    },
    USER_MANAGEMENT: {
      USER_COMPANY: {
        SELECT_ORGANIZATION:"Select an organization",
        EDIT_USER:"Edit User",
        CNPJ:"CNPJ",
        STATUS:"Status",
        ADMIN:"Admin",
        MEMBER:"Member",
        MEMBERS:"Members",
        INVITE_USER:"Invite User",
        INVITE_EMAIL:"Invite Email",
        ROLE:"Role",
        USERS:"Users",
        NEW_ORGANIZATION:"New Organization",
        ORGANIZATION: "Organization",
        INVITES_AVAILABLE:"Invites available",
        LIST: "Organizations list",
        COMPANY: "Company",
        CORPORATE_NAME: "Corporate name",
        TRADING_NAME: "Trading name",
        TELEPHONE: "Telephone",
        CELLPHONE: "Cellphone",
        CONTACT_NAME: "Contact name",
        CONTACT_EMAIL: "Contact email",
        FINANCIAL_EMAIL: "Finacial email",
        FINANCIAL_OFFICER: "Financial officer",
        ADDITIONAL_INFORMATION: "Additional information",
        DELETE: "Delete user company",
        IS_DELETING: "Is deleting",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE:
          "Are you sure to permanently delete?\nAll linked users will lose their link to this company",
        ORG_NAME: "Organization name",
        OWNER_NAME: "Organization owner",
        USER_ID: "User ID"
      },
      EDIT_ROLE: {
        ROLE: "Role",
      },
      USERS: {
        USERS_LIST: "Users list",
        NEW_USER: "New user",
      },
      EDIT_USER: {
        USER: "User",
        ORGANIZATIONS:"Organizations",
        GENDER_FEMALE: "Female",
        GENDER_MALE: "Male",
        BIRTHDAY: "Birthday",
        GENDER: "Gender",
        ABOUT: "About",
        PHONE_NUMBER: "Phone number",
        SITE: "Site",
        COMPANY_NAME: "Company name",
        CPF_CNPJ: "CPF/CNPJ",
        REALM_ROLES: "Roles",
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        EDIT: "Edit",
        CREATE: "Create",
        BACK: "Back",
        RESET: "Reset",
        BASIC_INFO: "Basic Information",
        PROFILE_INFO: "Profile Information",
        ADDRESS_INFO: "Address Information",
        ENTER_USERNAME: "Enter username/email",
        ENTER_USERNAME_TIP: "Please enter your username",
        USERNAME_WAS_ENTERED_CORRECT: "Username was entered correct",
        USERNAME_IS_REQUIRED: "Username is required",
        USERNAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Username should have at least 3 symbols",
        USERNAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Username should have maximum 100 symbols",
        ENTER_CPF_CNPJ: "Enter CPF/CNPJ",
        ENTER_CPF_CNPJ_TIP: "Please enter with CPF/CNPJ",
        CPF_CNPJ_WAS_ENTERED_CORRECT: "CPF/CNPJ was entered correct",
        CPF_CNPJ_IS_REQUIRED: "CPF/CNPJ is required",
        SELECT_PLAN: "Select plan",
        SELECT_PLAN_TIP: "Please select a plan",
        SELECT_ACTIVE: "Select active",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        SELECT_ACTIVE_TIP: "Please select active",
        SELECT_ENABLED: "Select enabled",
        ENABLED: "Enabled",
        DISABLED: "Disabled",
        SELECT_ENABLED_TIP: "Please select enabled",
        SELECT_CREDENTIALS_EXPIRED: "Selecy expired credentials",
        SELECT_CREDENTIALS_EXPIRED_TIP: "Please select expired credentials",
        SELECT_EXPIRED: "Select expired",
        SELECT_EXPIRED_TIP: "Please select expired",
        SELECT_LOCKED: "Select locked",
        SELECT_LOCKED_TIP: "Please select locked",
        CANCEL: "Cancel",
        SAVE: "Save",
      },
      USER_PROFILE_EDIT: {
        DATE_OF_BIRTH: "Date of birth",
        DATE_OF_BIRTH_WAS_ENTERED_CORRECT: "Date of birth was entered correct",
        DATE_OF_BIRTH_TIP: 'Please enter date of birth in "dd/mm/yyyy" format',
        SELECT_GENDER: "Select gender",
        FEMALE: "Female",
        MALE: "Male",
        SELECT_GENDER_TIP: "Please select gender",
        ENTER_REAL_NAME: "Enter real name",
        ENTER_REAL_NAME_TIP: "Please enter real name",
        ENTER_REAL_NAME_WAS_ENTERED_CORRECT: "Realname was entered correct",
        ENTER_REAL_NAME_IS_REQUIRED: "Realname is required",
        ENTER_REAL_NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Realname should have at least 3 symbols",
        ENTER_REAL_NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Realname should have maximum 100 symbols",
        ENTER_EMAIL: "Enter email",
        ENTER_EMAIL_TIP: "Please enter email",
        ENTER_EMAIL_WAS_ENTERED_CORRECT: "Email was entered correct",
        ENTER_EMAIL_IS_REQUIRED: "Email is required",
        ENTER_EMAIL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Email should have at least 3 symbols",
        ENTER_EMAIL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Email should have maximum 100 symbols",
        ENTER_PHONE_NUMBER: " Enter phone number",
        ENTER_PHONE_NUMBER_TIP: "Please enter phone number",
        ENTER_PHONE_NUMBER_WAS_ENTERED_CORRECT:
          "Phone number was entered correct",
        ENTER_PHONE_NUMBER_IS_REQUIRED: "Phone number is required",
        ENTER_PHONE_NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Phone number should have at least 3 symbols",
        ENTER_PHONE_NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Phone number should have maximum 100 symbols",
        ENTER_SITE: "Enter site",
        ENTER_SITE_TIP: "Please enter site",
        ENTER_SITE_WAS_ENTERED_CORRECT: "Site was entered correct",
        ENTER_SITE_IS_REQUIRED: "Site is required",
        ENTER_SITE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Site should have at least 3 symbols",
        ENTER_SITE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Site should have maximum 100 symbols",
        CANCEL: "Cancel",
        SAVE: "Save",
      },
      DELETE_USER: {
        USER_DELETE: "User delete",
        USER_IS_DELETING: "User is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER:
          "Are you sure to permanently delete this user?",
        CANCEL: "Cancel",
        DELETE: "Delete",
      },
      DELETE_USERS: {
        USERS_DELETE: "Users delete",
        USERS_IS_DELETING: "Users is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS:
          "Are you sure to permanently delete selected users?",
        CANCEL: "Cancel",
        DELETE: "Delete",
      },
      FETCH_USERS: {
        FETCH_SELECTED_USERS: "Fetch selected users",
        USERNAME: "User name",
        STATUS: "Status",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        PENDING: "Pending",
        CANCEL: "Cancel",
        FETCH: "Fetch",
      },
      UPDATE_USERS: {
        STATUS_UPDATE_FOR_SELECTED_USERS: "Status update for selected users",
        USERNAME: "User name",
        STATUS: "Status",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        PENDING: "Pending",
        CANCEL: "Cancel",
        UPDATE_STATUS: "Update status",
      },
      ROLES: {
        ROLES_COMPOSITE: "Role composition",
        ROLES_LIST: "Roles list",
        ROLE: "Role",
      },
      DELETE_ROLE: {
        ROLE_DELETE: "Role delete",
        ROLE_IS_DELETING: "Role is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ROLE:
          "Are you sure to permanently delete this role?",
      },
      DELETE_ROLES: {
        ROLES_DELETE: "Roles delete",
        ROLES_IS_DELETING: "Roles is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_ROLES:
          "Are you sure to permanently delete selected roles?",
      },
      FETCH_ROLES: {
        FETCH_SELECTED_ROLES: "Fetch selected roles",
      },
      UPDATE_ROLES: {
        STATUS_UPDATE_FOR_SELECTED_ROLES: "Status update for selected roles",
      },
      SITES: {
        SITES_LIST: "Sites list",
        NEW_SITE: "New site",
        PRIMARY_COLOR: "Primary color",
        SECONDARY_COLOR: "Secondary color",
        ACCENT_COLOR: "Accent color",
      },
      DELETE_SITE: {
        SITE_DELETE: "Site delete",
        SITE_IS_DELETING: "Site is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_SITE:
          "Are you sure to permanently delete this site?",
      },
      DELETE_SITES: {
        SITES_DELETE: "Sites delete",
        SITES_IS_DELETING: "Sites is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_SITES:
          "Are you sure to permanently delete selected sites?",
      },
      FETCH_SITES: {
        FETCH_SELECTED_SITES: "Fetch selected sites",
      },
      UPDATE_SITES: {
        STATUS_UPDATE_FOR_SELECTED_SITES: "Status update for selected sites",
      },
      LEVELS: {
        LEVELS_LIST: "Levels List",
        NEW_LEVEL: "New level",
      },
      EDIT_LEVEL: {
        SELECT_VIEW_MENU: "Select view menu",
        VIEW_MENU: " view menu",
        SELECT_VIEW_ASSETS: "Select view assets",
        VIEW_ASSETS: " view assets",
        SELECT_VIEW_NOTIFICATIONS: "Select view notifications",
        VIEW_NOTIFICATIONS: " view notifications",
        SELECT_VIEW_CHATS: "Select view chats",
        VIEW_CHATS: " view chats",
        SELECT_VIEW_OPPORTUNITIES: "Select view opportunities",
        VIEW_OPPORTUNITIES: " view opportunities",
        SELECT_VIEW_ACTIVE_INFOMAPS: "Select view active infomaps",
        VIEW_ACTIVE_INFOMAPS: " view active infomaps",
        SELECT_VIEW_MAPBOX: "Select view mapbox",
        VIEW_MAPBOX: " view mapbox",
        SELECT_VIEW_EBI: "Select view e-BI",
        VIEW_EBI: " view e-BI",
        SELECT_VIEW_ECOMAP: "Select view ecomap",
        VIEW_ECOMAP: " view ecomap",
        SELECT_VIEW_ECOBOARD: "Select view ecoboard",
        VIEW_ECOBOARD: " view ecoboard",
        SELECT_VIEW_ECOFLOW: "Select view ecoflow",
        VIEW_ECOFLOW: " view ecoflow",
        PLEASE_SELECT: "Please select",
      },
      DELETE_LEVEL: {
        LEVEL_DELETE: "Level delete",
        LEVEL_IS_DELETING: "Level is deleting",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LEVEL:
          "Are you sure to permanently delete this level?",
      },
      DELETE_LEVELS: {
        LEVELS_DELETE: "Levels delete",
        LEVELS_IS_DELETING: "Levels is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LEVELS:
          "Are you sure to permanently delete selected levels?",
      },
      FETCH_LEVELS: {
        FETCH_SELECTED_LEVELS: "Fetch selected levels",
      },
      UPDATE_LEVELS: {
        STATUS_UPDATE_FOR_SELECTED_LEVELS: "Status update for selected levels",
      },
      PLANS: {
        PLANS_LIST: "Plans list",
        PRICE: "Price",
      },
      EDIT_PLAN: {
        PLAN: "Plan",
        PRICE: "Price",
        REFERENCE_CODE: "Reference code",
        ACCESS_DURATION: "Access duration",
      },
      DELETE_PLAN: {
        PLAN_DELETE: "Plan delete",
        PLAN_IS_DELETING: "Plan is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PLAN:
          "Are you sure to permanently delete this plan?",
      },
      DELETE_PLANS: {
        PLANS_DELETE: "Plans delete",
        PLANS_IS_DELETING: "Plans is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_PLANS:
          "Are you sure to permanently delete selected plans?",
      },
      FETCH_PLANS: {
        FETCH_SELECTED_PLANS: "Fetch selected plans",
      },
      UPDATE_PLANS: {
        STATUS_UPDATE_FOR_SELECTED_PLANS: "Status update for selected plans",
      },
      USERS_MAP: {
        USERS_MAP_LIST: "Users map list",
        USER_MAP: "User map",
        USER_LEVEL: "User level",
        USER_MAP_2D: "User map 2D",
        USER_MAP_3D: "User map 3D",
      },
      EDIT_USER_MAP: {
        USER: "user",
        SELECT_USER: "Select user",
        PLEASE_SELECT: "Please select",
        SELECT_USER_LEVEL: "Select user level",
        USER_LEVEL: "user level",
        SELECT_USER_MAP_2D: "Select user map 2D",
        SELECT_USER_MAP_3D: "Select user map 3D",
        USER_MAP_2D: " user map 2D",
        USER_MAP_3D: " user map 3D",
      },
      DELETE_USER_MAP: {
        USER_MAP_DELETE: "User map delete",
        USER_MAP_IS_DELETING: "User map is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_MAP:
          "Are you sure to permanently delete this user map?",
      },
      DELETE_USERS_MAP: {
        USERS_MAP_DELETE: "Users map delete",
        USERS_MAP_IS_DELETING: "Users map os deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_MAP:
          "Are you sure to permanently delete selected users map?",
      },
      FETCH_USERS_MAP: {
        FETCH_SELECTED_USERS_MAP: "Fetch selected users map",
      },
      UPDATE_USERS_MAP: {
        STATUS_UPDATE_FOR_SELECTED_USERS_MAP:
          "Status update for selected users map",
      },
      LOGINS: {
        LOGINS_LIST: "Logins list",
        CREATE_DATE: "Create date",
        IP_ADDRESS: "Ip address",
      },
      USERS_EBI: {
        USERS_EBI_LIST: "e-BI List",
        NEW_USER_EBI: "New e-BI",
        USER_LEVEL: "User Level",
        EBI_TYPE: "e-BI Type",
        ORDER: "Order",
      },
      EDIT_USER_EBI: {
        USER_EBI: "e-BI",
        SELECT_USER: "Select User",
        PLEASE_SELECT: "Please select",
        SELECT_USER_LEVEL: "Select User Level",
        USER_LEVEL: " User Level",
        SELECT_EBI_TYPE: "Select e-BI Type",
        EBI_TYPE: " e-BI Type",
        EBI_URL: "e-BI URL",
        ORDER: "Order",
        THUMBNAIL: "Thumbnail",
      },
      DELETE_USER_EBI: {
        USER_EBI_DELETE: "e-BI delete",
        USER_EBI_IS_DELETING: "e-BI is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI:
          "Are you sure to permanently delete this e-BI",
      },
      DELETE_USERS_EBI: {
        USERS_EBI_DELETE: "e-BIs delete",
        USERS_EBI_IS_DELETING: "e-BIs is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI:
          "Are you sure to permanently delete selected e-BIs?",
      },
      FETCH_USERS_EBI: {
        FETCH_SELECTED_USERS_EBI: "Fetch selected e-BIs",
      },
      UPDATE_USERS_EBI: {
        STATUS_UPDATE_FOR_SELECTED_USERS_EBI:
          "Status update for selected e-BIs",
      },
      USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT_LIST: "User access management list",
        NEW_USER_ACCESS_MANAGEMENT: "New user access management",
        USER_EMAIL_USERNAME: "User email / User name",
        USER_REALNAME: "User real name",
        COMPANY_NAME: "Company name",
        CREDENTIALS_EXPIRED: "Credentials expired",
        ACCESS_EXP_DATE: "Access exp date",
      },
      EDIT_USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT: "user access management",
        USER_EMAIL_USERNAME: "User email / User name",
        USER_REALNAME: "User real name",
        COMPANY_NAME: "Company name",
        SELECT_ENABLED: "Select enabled",
        PLEASE_SELECT: "Please select",
        SELECT_CREDENTIALS_EXPIRED: "Select credentials expired",
        CREDENTIALS_EXPIRED: " credentials expired",
        SELECT_EXPIRED: "Select expired",
        SELECT_LOCKED: "Select locked",
        ACCESS_EXP_DATE: "Access exp date",
        DD_MM_YYYY: "DD/MM/YYYY",
        PLEASE_ENTER: "Please, enter",
        IN_DD_MM_YYYY_FORMAT: "In DD/MM/YYYY format",
      },
      DELETE_USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT_DELETE: "User access management delete",
        USER_ACCESS_MANAGEMENT_IS_DELETING:
          "User access management is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ACCESS_MANAGEMENT:
          "Are you sure to permanently delete this user access management?",
      },
      DELETE_USERS_ACCESS_MANAGEMENT: {
        USERS_ACCESS_MANAGEMENT_DELETE: "Users access management delete",
        USERS_ACCESS_MANAGEMENT_IS_DELETING:
          "Users access management is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Are you sure to permanently delete selected users access management?",
      },
      FETCH_USERS_ACCESS_MANAGEMENT: {
        FETCH_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Fetch selected users access management",
      },
      UPDATE_USERS_ACCESS_MANAGEMENT: {
        STATUS_UPDATE_FOR_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Status update for selected users access management",
      },
      COMPANIES: {
        COMPANIES_LIST: "Organizations list",
        COMPANY: "Company",
        CNPJ: "CNPJ",
        EMAIL: "Email",
        PHONE_NUMBER: "Phone number",
      },
      EDIT_COMPANY: {
        COMPANY: "company",
        CNPJ: "CNPJ",
        EMAIL: "Email",
        PHONE_NUMBER: "Phone number",
      },
      DELETE_COMPANY: {
        COMPANY_DELETE: "Company delete",
        COMPANY_IS_DELETING: "Company is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_COMPANY:
          "Are you sure to permanently delete this company?",
      },
      DELETE_COMPANIES: {
        COMPANIES_DELETE: "Companies",
        COMPANIES_IS_DELETING: "Companies is deleting ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_COMPANIES:
          "Are you sure permanently delete selected companies?",
      },
      FETCH_COMPANIES: {
        FETCH_SELECTED_COMPANIES: "Fetch selected companies",
      },
      UPDATE_COMPANIES: {
        STATUS_UPDATE_FOR_SELECTED_COMPANIES:
          "Status update for selected companies",
      },
      USERS_ADDRESS: {
        USERS_ADDRESS_LIST: "Users Address List",
        USER_ADDRESS: "User Address",
        STREET: "Street",
        NUMBER: "Number",
        COMPLEMENT: "Complement",
        NEIGHBORHOOD: "Neighborhood",
        POSTAL_CODE: "Postal Code",
        STATE: "State",
        CITY: "City",
      },
      EDIT_USER_ADDRESS: {
        SELECT_USER: "Select User",
        PLEASE_SELECT: "Please select",
        STREET: "Street",
        NUMBER: "Number",
        COMPLEMENT: "Complement",
        NEIGHBORHOOD: "Neighborhood",
        POSTAL_CODE: "Postal Code",
        SELECT_STATE: "Select State",
        STATE: "State",
        SELECT_CITY: "Select City",
        CITY: "City",
        USER_ADDRESS: "Address",
      },
      DELETE_USER_ADDRESS: {
        USER_ADDRESS_DELETE: "User Address delete",
        USER_ADDRESS_IS_DELETING: "User Address is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ADDRESS:
          "Are you sure to permanently delete this User Address?",
      },
      DELETE_USERS_ADDRESS: {
        USERS_ADDRESS_DELETE: "Users address delete",
        USERS_ADDRESS_IS_DELETING: "Users address is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ADDRESS:
          "Are you sure to permanently delete selected User Addresses?",
      },
      FETCH_USERS_ADDRESS: {
        FETCH_SELECTED_USERS_ADDRESS: "Fetch selected User Addresses",
      },
      UPDATE_USERS_ADDRESS: {
        STATUS_UPDATE_FOR_SELECTED_USERS_ADDRESS:
          "Status update for selected User Addresses",
      },
      USERS_EBI_TYPE: {
        USERS_EBI_TYPE_LIST: "e-BI Type List",
        NEW_USER_EBI_TYPE: "New e-BI Type",
      },
      EDIT_USER_EBI_TYPE: {
        USER_EBI_TYPE: "e-BI Type",
        ORDER: "Order",
      },
      DELETE_USER_EBI_TYPE: {
        USER_EBI_TYPE_DELETE: "e-BI Type delete",
        USER_EBI_TYPE_IS_DELETING: "e-BI Type is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI_TYPE:
          "Are you sure to permanently delete this e-BI Type?",
      },
      DELETE_USERS_EBI_TYPE: {
        USERS_EBI_TYPE_DELETE: "e-BI Types delete",
        USERS_EBI_TYPE_IS_DELETING: "e-BI Types is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI_TYPE:
          "Are you sure to permanently delete selected e-BI Types?",
      },
      FETCH_USERS_EBI_TYPE: {
        FETCH_SELECTED_USERS_EBI_TYPE: "Fetch selected e-BI Types",
      },
      UPDATE_USERS_EBI_TYPE: {
        STATUS_UPDATE_FOR_SELECTED_USERS_EBI_TYPE:
          "Status update for selected e-BI Types",
      },
      FEED: {
        FEEDS_LIST: "Feeds list",
        FEED: "Feed",
        FEED_TYPE: "Feed Type",
        ACTIVITY: "Activity",
        BLOG: "Blog",
        CALENDAR: "Calendar",
        CREATE_DATE: "Create date",
        LAST_UPDATE: "Last update",
      },
      EDIT_FEED: {
        DESCRIPTION: "Description",
        FEED_TYPE: "Feed type",
        COLOR: "Color",
        CATEGORY: "Category",
        TEXT: "Text",
        LINK: "Link",
        TITLE: "Title",
        MEDIA_LINK: "Media link",
        EVENT_DATE: "Event date",
      },
      DELETE_FEED: {
        FEED_DELETE: "Feed delete",
        FEED_IS_DELETING: "Feed is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FEED:
          "Are you sure to permanently delete this Feed?",
      },
      DELETE_FEEDS: {
        FEEDS_DELETE: "Feeds delete",
        FEEDS_IS_DELETING: "Feeds is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_FEEDS:
          "Are you sure to permanently delete selected Feeds?",
      },
      FETCH_FEED: {
        FETCH_SELECTED_FEEDS: "Fetch selected Feeds",
      },
      UPDATE_FEED: {
        STATUS_UPDATE_FOR_SELECTED_FEEDS: "Status update for selected Feeds",
      },
    },
    TUTORIAL_MANAGEMENT: {
      TUTORIAL_CATEGORIES: "Categories",
      TUTORIAL: "Tutorial",
      NAME: "Name",
      LAST_UPDATE: "Last update",
      CREATE_DATE: "Creation date",
      DESCRIPTION: "Description",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      CATEGORIES: {
        NEW_CATEGORY: "New category",
        EDIT_CATEGORY: "Edit category",
        CATEGORY: "Category",
        DELETE_CATEGORY: "Delete category",
        CATEGORY_IS_DELETING: "The categories are being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_CATEGORIES:
          "Are you sure to delete the selected categories?",
      },
      TUTORIALS: {
        TUTORIALS: "Tutorials",
        CREATE_TUTORIAL: "Create tutorial",
        DELETE_TUTORIAL: "Delete tutorial",
        EDIT_TUTORIAL: "Edit tutorial",
        MEDIA_LINK: "Media link",
        IMAGE_LINK: "Image link",
        TUTORIAL_IS_BEING_DELETED:
          "Are you sure you want to delete this tutorial?",
        TUTORIAL_IS_DELETING: "The tutorials are being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_TUTORIAL:
          "Are you sure to delete the selected tutorials?",
      },
    },

    EXPORT_MANAGEMENT:{
      NAME:"name",
      STATUS:"Status",
      EXPORTED_DATE:"Exported Date",
      CREATE_DATE:"Creation date",
      MODIFIED_DATE:"Modified date",
      IS_OUTDATED:"Is outdated",
      MAX_AREA_ALLOWED:"Max area allowed",
      MIN_AREA_ALLOWED:"Min area allowed",
      MEDIA_FILE_URL:"Media file url",
      MESSAGE:"Message",
      DESCRIPTION:"Description",
      ENABLED:"Is enabled",
      YES:"Yes",
      NO:"No", 
      ERROR_AREA_MAX:"The maximum area must be greater than the minimum",
      ERROR_NAME:"Name is required.",
      CONFIGURATION:"Configuration",
      LAYERS:"Layers",
      PRICE_PER_HECTARE:"Price per hectare",
      LAYER:"Layer",
      RATIO:"Ratio",
      LVC_SHORT:"Version",
      TRIM_CONFIGURATION:{
        TRIM_CONFIGURATION:"Exporter configuration",
        CREATE_TRIM_CONFIGURATION:"Create Exporter",
        ADD_TRIM_CONFIGURATION:"Add Exporter",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_CONFIGURATION:"Are you sure you want to delete this Exporter configuration?",
        TRIM_CONFIGURATION_IS_BEING_DELETED:"The Exporter configurations are being deleted...",
        DELETE_TRIM_CONFIGURATION:"Delete Exporter configurations",
        EDIT_TRIM_CONFIGURATION:"Edit Exporter configuration",
      },
      TRIM_LAYERS:{
        SYNC_MAP_LAYERS: "Sync Map Layers",
        TRIM_LAYERS:"Exporter Layers",
        EXPORTER_LAYERS:"Exporter Layers",
        CREATE_TRIM_LAYER:"Create Exporter layer",
        DELETE_TRIM_LAYER:"Delete Exporter layer",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_LAYER:"Are you sure you want to delete this Exporter layer?",
        EDIT_TRIM_LAYER:"Edit Exporter layer",
        TRIM_LAYER_IS_BEING_DELETED:"The Exporter layer is being deleted...",
        PRICE_MIN_ERROR:"The price cannot be negative",
        EDIT_TRIM_LAYERS: "Edit Exporter layer",
        SELECTED_TRIM_LAYERS_COUNT: "Selected layers:",
        TRIM_CONFIGURATION_UPDATE_FOR_SELECTED_LAYERS:
          "Update Exporter configuration for selected layers",
        DELETE_TRIM_LAYERS: {
          TRIM_LAYERS_DELETE: "Delete Exporter layers",
          ARE_YOU_SURE_TO_DELETE_SELECTED_TRIM_LAYERS:
            "Are you sure you want to disable the selected Exporter layers?",
          TRIM_LAYERS_ARE_BEING_DELETED:
            "Exporter layers are being disabled.",
        },
      },
      TRIM_RATIO:{
        TRIM_RATIO:"Exporter ratio",
        MULTIPLIER:"Multiplier",
        CREATE_TRIM_RATIO:"Create Exporter ratio",
        DELETE_TRIM_RATIO:"Delete Exporter ratio",
        EDIT_TRIM_RATIO:"Edit Exporter ratio",
        USER_ROLE:"User role",
        TRIM_RATIO_IS_BEING_DELETED:"The Exporter ratio is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_RATIO:"Are you sure you want to delete this Exporter ratio?",
        ERROR_MIN_TRIM_MULTIPLIER:"The multiplier must be positive"
      }

    },
    PAGINATOR: {
      LOADING: "Loading...",
      SHOWING: "Showing rows",
      TO: "to",
      OF: "of",
    },
    USER_DROPDOWN: {
      NEW_GROUP: "New group",
      CONTACTS: "Contacts",
      GROUPS: "Groups",
      CALLS: "Calls",
      SETTINGS: "Settings",
      HELP: "Help",
      PRIVACY: "Privacy",
    },
    MAP_DATA_MANAGEMENT: {
      MAP_CATEGORY_LAYERS: {
        ACTIVE_ON_INIT: "Active on init",
        ACTIVATE_ON_INIT: "Activate on init",
        DEACTIVATE_ON_INIT: "Deactivate on init",
        SELECT_CATEGORY: "Select category",
        MOVE_CATEGORY: "Move to another category",
        MOVING: "Moving",
        MOVE: "Move",
      },
      DELETE_CATEGORIES: {
        CATEGORIES_DELETE: "Delete categories",
        CATEGORIES_IS_DELETING: "The categories are being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_CATEGORIES:
          "Are you sure to delete the selected categories?",
      },
      DELETE_MAP_CATEGORY: {
        MAP_CATEGORY_DELETE: "Delete category",
        MAP_CATEGORY_IS_DELETING: "The category is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP_CATEGORY:
          "Are you sure to delete this category?",
      },
      DELETE_MAP: {
        MAP_DELETE: "Delete map",
        MAP_IS_DELETING: "The map is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP:
          "Are you sure to delete this map?",
      },
      CATEGORIES: {
        SEARCH_CATEGORIES: "Search category",
        IN_ALL_FIELDS: "in all fields",
        ADD_CATEGORY: "Add category",
        SELECTED_RECORDS_COUNT: "Selected categories:",
        DELETE_ALL: "Delete all",
      },
      CATEGORY: {
        TITLE: "Category",
        EDIT_CATEGORY: "Edit category",
        LAYERS: "Layers",
        ORDER: "Order",
        TYPE: "Type",
        VISIBLE: "Visible",
        EXPAND_ON_INIT: "Expand on init",
        USER_SID: "User SID",
        CONFIRM_EDIT: "Confirm",
        SELECT_CATEGORY: "Select category",
        MOVE_CATEGORY: "Move to another category",
        MOVING: "Moving",
        MOVE: "Move",
        UNCATEGORIZED: "Uncategorized",
        ECOTX_CATEGORY: "Ecotx Category",
        USER_CATEGORY: "User Category",
        BASIC_INFO: "Basic Information",
      },
      APPLY_STYLE: {
        APPLY_STYLE: "Apply style",
        SELECT_LAYER: "Layer",
        SELECT_GEOMETRY_STYLE: "Geometry style",
        SELECT_GEOMETRY_PROPERTY: "Geometry property",
        SELECT_PROPERTY_VALUE: "Geometry property value",
        INTERVAL_START: "Interval start",
        INTERVAL_END: "Interval end"
      },
      BASE_MAP: {
        BASE_MAP: "base map",
        BASE_MAPS_LIST: "Base Map List",
        DISPLAY_ORDER: "Display Order",
      },
      EDIT_BASE_MAP: {
        BASE_MAP: "base map",
        BASEMAP: "Thumbnail",
        FILE_UPLOAD: "File upload",
        URL: "URL style",
        DISPLAY_ORDER: "Display Order",
        LOGO_URL: "Logo url",
      },
      DELETE_BASE_MAP: {
        BASE_MAP_DELETE: "Delete Base Map",
        BASE_MAP_IS_DELETING: "The Base Map is being Deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_BASE_MAP:
          "Are you sure you want to permanently delete this Base Map?",
      },
      DELETE_BASE_MAPS: {
        BASE_MAPS_DELETE: "Delete Base Maps",
        BASE_MAPS_IS_DELETING: "The Base Maps are being Deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_BASE_MAPS:
          "Are you sure you want to permanently delete the selected Base Maps?",
      },
      FETCH_BASE_MAPS: {
        FETCH_SELECTED_BASE_MAPS: "Fetch selected Base Maps",
      },
      UPDATE_BASE_MAPS: {
        STATUS_UPDATE_FOR_SELECTED_BASE_MAPS:
          "Status Update for selected Base Maps",
      },
      LAYERS: {
        SEARCH: "Search layer",
        LAYERS_LIST: "List of layers",
        IMPORT_STARTED: "Import started",
        IMPORT_COMPLETED: "Import completed",
        IN_ALL_FIELDS: "in all fields",
        IMPORT_FAILED: "Import failed",
        VALIDATION_IN_PROGRESS: "Validation in progress",
        APPLYING_DICTIONARIES: "Applying dictionaries",
        APPLYING_STYLES: "Applying styles",
        CLEANUP_COMPLETED: "Cleanup completed",
        PUBLISHED: "Published",
        PREVIEW: "Preview",
        STARTED: "Started",
        REQUESTED: "Requested",
        PENDING_CLEANUP: "Pending cleanup",
        IMPORT_IN_PROGRESS: "Import in progress",
        FAILED_TO_REQUEST: "Failed to request",
        CLEANUP_IN_PROGRESS: "Cleanup in progress",
        FAILED_FAST_IMPORT_PROCESS: "Failed fast import process",
        FAILED_FAST_IMPORT_COMPLETED: "Failed fast import completed"
      },
      EDIT_LAYER: {
        UNABLE_TO_EDIT_OR_PUBLISH_THIS_LAYER: "Unable to edit or publish this layer",
        STYLE_JSON: "Style as JSON",
        ZOOM_LOCK: "Zoom lock",
        BUCKET:"Minio bucket",
        COUNT_GEOMETRY_DATA_PROD:"Count geometry data prod",
        COUNT_GEOMETRY_DATA_STAGE:"Count geometry data stage",
        COUNT_GEOMETRY_PROPERTY_PROD:"Count geometry property prod",
        COUNT_GEOMETRY_PROPERTY_STAGE:"Count geometry property stage",
        COUNT_GEOMETRY_PROPERTY_VALUE_PROD:"Count geometry property value prod",
        COUNT_GEOMETRY_PROPERTY_VALUE_STAGE:"Count geometry property value stage",
        COUNT_LAYER_PROD:"Count layer prod",
        COUNT_LAYER_STAGE:"Count layer stage",
        MESSAGE:"Message",
        MINIO_PATH:"Minio path",
        OWNER:"Owner",
        UPDATED:"Updated",
        LVC_DETAILS:"Import information",
        THERE_IS_ALREADY_A_PUBLISHED_VERSION: "There is already a published version of this layer",
        CANNOT_IMPORT_THE_LAYER: "Cannot import the layer",
        CHANGE_THE_LAYER_VERSION: "To continue, you need to change the layer from this version to the next.",
        THERE_ARE_ALREADY_LARGER_VERSIONS: "It is not possible to import the layer, as there are already larger versions of this layer already published."
      },
      DELETE_LAYER: {
        LAYER_DELETE: "Delete Layer",
        LAYER_IS_DELETING: "The Layer is being deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LAYER:
          "Are you sure you want to permanently delete this layer?",
      },
      DELETE_LAYERS: {
        LAYERS_DELETE: "Delete Layers",
        LAYERS_IS_DELETING: "The Layers are being deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LAYERS:
          "Are you sure you want to permanently delete the selected layers?",
      },
      FETCH_LAYERS: {
        FETCH_SELECTED_LAYERS: "Fetch selected layers",
      },
      GEOMETRY: {
        MARKER: "Marker",
        LINE_ADD: "Add",
        LINE_CLEAR: "Clear",
        RESET_MAP_VIEW: "Reset Map View",
        CREATING: "Creating",
        CREATE_STYLE: "Create Style",
        DELETING: "Deleting",
        ARE_U_SURE: "Are you sure?",
        DELETE_STYLE: "Delete Geometry Style",
        ROW_STYLE: "Style",
        NEW_STYLE: "Create Style",
        STYLE_LIST: "Geometry Style List",
        TITLE: "Geometry Style",
        GEOMETRY: "Geometry",
        POLYGON: "Polygon",
        POINT: "Point",
        LINE: "Line",
        SYMBOL: "Symbol",
        OPTION: "Option",
        PROPERTY: "Property",
        STYLE_TYPE: "Style Type",
      },
      FILTER_EXPRESSION: {
        DISPLAY_ORDER: "Display order",
        CONDITION_IS_REQUIRED: "Condition is required",
        LAYER_IS_REQUIRED: "Layer is required",
        GEOMETRY_IS_REQUIRED: "Geometry property is required",
        MAP_IS_REQUIRED: "Map is required",
        ARE_U_SURE: "Are you sure?",
        EXPRESSIONS: "Expressions",
        MAP_AND_LAYER_TAB: "Map & Layer",
        SELECT_EXPRESSIONS: "Select Expressions",
        GEOMETRY_PROP: "Geometric Property",
        ADD_GEOMETRY_PROP: "Add Expressões",
        CONFIRM_EDIT: "Confirm",
        ICON: "Icon",
        NEW_FILTER_EXPRESSION: "Create Filter",
        CONDITION: "Condition",
        LAYER_NAME: "Layer Name",
        GEOMETRY_PROP_NAME: "Geometry Property Name",
        TITLE: "Filter Expressions",
        ROW_NAME: "Row Name",
        ACTIONS: "Actions",
        FILTER_EXPRESSION: "Filter",
        DELETING: "Deleting",
      },
      DELETE_FILTER_EXPRESSION: {
        FILTER_EXPRESSION_DELETE: "Delete Filter Expression",
        FILTER_EXPRESSION_IS_DELETING:
          "The Filter Expression is being deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FILTER_EXPRESSION:
          "Are you sure you want to permanently delete this Filter Expression?",
      },
      DELETE_FILTER_EXPRESSIONS: {
        FILTER_EXPRESSIONS_DELETE: "Delete Filter Expressions",
        FILTER_EXPRESSIONS_IS_DELETING:
          "The Filter Expressions are being deleted ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_FILTER_EXPRESSIONS:
          "Are you sure you want to permanently delete the selected Filter Expressions?",
      },
      MAP_LIST: {
        MAPS_LIST: "Map List (Backoffice)",
        MAPS: "My Maps",
        WORKSPACES: "My Workspaces",
        NEW_MAP: "New Map",
        DELETE_MAP: "Delete Map",
        CATEGORY_TABLE: "Layers Categories",
        UNCATEGORIZED_LAYER: "Uncategorized Layers",
      },
      EDIT_MAP: {
        WELCOME_MESSAGE: "Welcome message",
        PUBLIC_MAP: "Public Map",
        HOST: "Host",
        BEARING: "Bearing",
        PITCH: "Pitch",
        BASIC_INFO: "Basic Information",
        LAYERS: "Layers",
        FILTERS: "Filters",
        ORIENTED_SEARCHES: "Oriented Searches",
        SEARCH_FILTERS: "Search Filters",
        ADD_ORIENTED_SEARCH: "Add Oriented Search",
        INITIAL_ZOOM: "Initial Zoom",
        SELECT_BASEMAP: "Basemap",
        PLEASE_SELECT: "Please select a ",
        BASEMAP: "Basemap",
        CENTER_LATITUDE: "Center Latitude",
        CENTER_LONGITUDE: "Center Longitude",
        ROLE: "Role",
        ADD_LAYER: "Add Layer",
        CATEGORIES: "Categories",
        NAME: "Name",
        CANCEL: "Cancel",
        SAVE: "Save",
        BACK: "Back",
        MAP: "Map",
      },
      FETCH_MAPS: {
        FETCH_SELECTED_MAPS: "Fetch selected maps",
      },
      IMPORT_LAYERS: {
        CLEAR_CACHE: "Clear cache",
        NOT_STARTED: "Not started",
        PENDING: "Pending",
        FINISHED: "Finished",
        ERROR: "Error",
        FILE_PATH: "File Path",
        SEE_BATCH: "See batch",
        RETRACT_BATCH: "Retract batch",
        REAPPLY_STYLE: "Reapply style",
        WILL_IT_BE_APPLIED: "Will it be applied?",
        GEOMETRY_STYLE_IN_VERSION: "Geometry style in version",
        PROPERTY_VALUE_IN_VERSION: "Property value in version",
        PROPERTY_IN_VERSION: "Property in version",
        UNIQUE_STYLE: "Unique style",
        STYLE: "Style on version",
        STYLE_TYPE: "Style type on version",
        STYLE_SETTINGS: "Style settings",
        LOAD_STYLES_FROM_PREVIOUS_VERSIONS:
          "Load styles from previous versions",
        DISPLAY_ORDER: "Display order",
        IS_THERE_IN_THE_VERSION: "Is there in the version",
        CURRENT_LAYER_PROPERTIES: "Current layer properties",
        LOAD_PROPERTIES_FROM_PREVIOUS_VERSIONS:
          "Load properties from previous versions",
        PROPERTY_SETTINGS: "Property settings",
        USE_VERSION: "Use version",
        APPLY: "Aplicar",
        BY_DISPLAY_NAME: "by display name",
        DICTIONARY_DESC: "Apply dictionary in properties",
        STYLE_DESC: "Apply styles on the layer",
        SUMMARY_DESC: "Summary of changes made",
        NEXT_STEP: "Next",
        PREVIOUS: "Previous",
        STYLE_DATAILS: "Style details",
        DICTIONARY_DATAILS: "Dictionary details",
        BY_PROPERTIES: "By properties",
        SETUP_STYLES: "Setup your styles",
        ORDER: "Order",
        VISIBLE: "Visible",
        RENAME_PROPS: "Rename the properties",
        SUMMARY: "Summary",
        STYLES: "Styles",
        DICTIONARY: "Dictionary",
        IMPORT_LAYERS_LIST: "Import Layer List",
        NEW_IMPORT_LAYER: "New import layer",
        OWNER: "Owner",
        STYLE_JSON: "Style as JSON",
        ZOOM_LOCK: "Zoom Lock",
        IMPORT_LAYER_DELETE: "Delete import layer",
        IMPORT_COMPLETED: "Import Process Completed",
        FAILED_FAST_IMPORT_PROCESS: "Failed Fast Import Process",
        FAILED_FAST_IMPORT: "Failed fast import",
        PREVIEW: "Preview",
        PREVIEW_LAYER: "Preview",
        REVIEW_LAYER: "Review",
        FAST_IMPORT_COMPLETED: "Fast Import Completed",
        FAILED_TO_REQUEST: "Failed to Request",
        PUBLISHED: "Published",
        PROGRESS: "Progress",
        STARTED: "Started",
        IMPORT_IN_PROGRESS: "Import in Progress",
        PUBLISH_LAYER: "Publish Layer",
        REQUESTED: "Requested",
        PENDING_CLEANUP: "Pending cleanup",
        CLEANUP_IN_PROGRESS: "Cleanup in progress",
        CLEANUP_COMPLETED: "Cleanup completed",
        UPDATE:'Update'
      },
      CREATE_MAP_LAYER: {
        CREATE: "Add Layer",
        PUBLISHED_LAYER: "Published Layers",
      },
      CREATE_IMPORT_LAYER: {
        MODAL_TITLE: "New Import Process",
        IMPORT: "Import",
        BOT: "Bot",
        MINIO: "MinIO",
        BATCH: "Batch",
        RASTER: "Raster",
        RASTER_NAME: "Raster Filename",
        RASTER_URL: "Raster URL",
        TYPE: "Type of import",
        STYLE_JSON: "Style as JSON",
        ZOOM_LOCK: "Zoom Lock",
        FILE: "File",
        FILE_UPLOAD: "File to Upload",
        MINIO_BUCKET: "MinIO Bucket",
        MINIO_PATH: "MinIO Path",
        FILE_NAME: "File name",
      },
      DELETE_IMPORT_LAYER: {
        IMPORT_LAYER_DELETE: "Delete Import Process",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_IMPORT_LAYER:
          "Are you sure you want to dele this Import Process?",
      },
      ORIENTED_SEARCH: {
        NONE: "None",
        REMOVE: "Remove",
        ADD: "Add",
        ORIENTED_SEARCH_LIST: "Oriented Search List",
        ADD_ORIENTED_SEARCH: "Add Oriented Search",
      },
      EDIT_ORIENTED_SEARCH: {
        ORIENTED_SEARCH: "Oriented Search",
        LAYER: "Layer",
        BASIC_INFO: "Basic Informationrmation",
        GEOMETRY_PROPERTIES: "Geometry properties",
      },
      DELETE_ORIENTED_SEARCH: {
        ORIENTED_SEARCH_DELETE: "Oriented Search Delete",
        ORIENTED_SEARCH_IS_DELETING: "Oriented Search is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ORIENTED_SEARCH:
          "Are you sure to permanently delete this Oriented Search?",
      },
      DELETE_MAP_LAYER: {
        MAP_LAYER_DELETE: "Delete Map Layer",
        MAP_LAYER_IS_DELETING: "Map Layer is deleting...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP_LAYER:
          "Are you sure you want to permanently delete this Layer?",
      },
      PUBLISH_IMPORT_LAYER: {
        MODAL_TITLE: "Publish Layer",
      },
      PRODUCTS_LIST: {
        CREATE_PRODUCT:"Create Product",
        PRODUCTS_LIST: "Products List",
        NEW_PRODUCT: "New Product",
        NAME: "Name",
        DESCRIPTION: "Description",
        ACTIVE: "Active",
        REQUIRED_ROLE: "Required Role",
        
      },
      EDIT_PRODUCT: {
        WELCOME_MESSAGE: "Welcome message",
        PUBLIC_MAP: "Public Map",
        HOST: "Host",
        BEARING: "Bearing",
        PITCH: "Pitch",
        BASIC_INFO: "Basic Information",
        LAYERS: "Layers",
        FILTERS: "Filters",
        ORIENTED_SEARCHES: "Oriented Searches",
        SEARCH_FILTERS: "Search Filters",
        ADD_ORIENTED_SEARCH: "Add Oriented Search",
        INITIAL_ZOOM: "Initial Zoom",
        SELECT_BASEMAP: "Basemap",
        PLEASE_SELECT: "Please select a ",
        BASEMAP: "Basemap",
        CENTER_LATITUDE: "Center Latitude",
        CENTER_LONGITUDE: "Center Longitude",
        ROLE: "Role",
        ADD_LAYER: "Add Layer",
        CATEGORIES: "Categories",
        NAME: "Name",
        CANCEL: "Cancel",
        SAVE: "Save",
        BACK: "Back",
        PRODUCT: "Product",
      },
      DELETE_PRODUCT: {
        PRODUCT_DELETE: "Delete product",
        PRODUCT_IS_DELETING: "The product is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PRODUCT:
          "Are you sure to delete this product?",
      },
      CREATE_LAYER: {
        CREATE: "Add Layer",
        PUBLISHED_LAYER: "Published Layers",
      },
      CATEGORY_LAYERS: {
        ACTIVE_ON_INIT: "Active on init",
        ACTIVATE_ON_INIT: "Activate on init",
        DEACTIVATE_ON_INIT: "Deactivate on init",
        SELECT_CATEGORY: "Select category",
        MOVE_CATEGORY: "Move to another category",
        MOVING: "Moving",
        MOVE: "Move",
      },
      DELETE_CATEGORY: {
        MAP_CATEGORY_DELETE: "Delete category",
        MAP_CATEGORY_IS_DELETING: "The category is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_CATEGORY:
          "Are you sure to delete this category?",
      },
    },
    ORDERS: {
      SELECTED_AREA: "Selected area",
      RETENTION_END_PERIOD: "Final retention period",
      TOTAL_LEADS: "Total leads",
      LEADS_DETAILS: "Leads details",
      IN_PROGRESS: "In progress",
      ERROR: "Error",
      SUCCESS: "Success",
      PENDING: "Pending",
      TRIM_EXPORTER: "Exporter",
      LEADS: "Leads",
      ORDER_CREATED: "Creation date",
      TRIM_DETAILS: "Exporter details",
      DISPLAY_NAME: "Name",
      IS_OUTDATED: " Is outdated",
      MEDIA_FILE_URL: "Link to the file",
      UPDATE_DATE: "Update date",
      LAYER_NAME: "Layer name",
      PRICE: "Price",
      FALSE: "No",
      TRUE: "Yes",
    },
    PAYMENTS_MANAGEMENT: {
      DETAILS: "Order Details",
      TRIM_DETAILS: "Exporter Details",
      LEADS_DETAILS: "Leads Details",
      DISPLAY_NAME: "Name",
      TOTAL_LEADS: "Total Leads",
      PRICE: "Price",
      RETENTION_END_PERIOD: "Retention end period",
      STATUS: "Status",
      MEDIA_FILE_URL: "Link to the file",
      SELECTED_AREA: "Selected Area",
      IS_OUTDATED: " Is outdated",
      LAYER_NAME: "Layer name",
      UPDATE_DATE: "Update date",
      ORDER: {
        CANCELED: "Canceled",
        CONFIRM: "Confirm",
        CANCEL_ORDER: "Cancel order",
        CANCEL: "Cancel",
        ORDER_IS_BEING_CANCELED: "Order is being canceled",
        ARE_YOU_SURE_TO_CANCEL_ORDER:
          "TAre you sure you want to cancel the order?",
        APPROVE: "Approve",
        APPROVE_ORDER: "Approve order",
        ORDER_IS_BEING_APPROVED: "Order is being approved",
        ARE_YOU_SURE_TO_APPROVE_ORDER:
          "Are you sure you want to approve the order?",
        USER_EMAIL: "User email",
        ORDER_NUMBER: "Order number",
        ORDER_PRICE: "Order price",
        SUCCESS: "Success",
        ERROR: "Error",
        PENDING: "Pending",
        PRODUCT: "Product",
        MESSAGE: "Message",
        CREATED: "Creation date",
        MODIFIED: "Last modified date",
      },
      TYPE: "Type",
      COUPON: {
        COUPON: "Coupon",
        COUPONS: "Coupons",
        COUPON_LIST: "Coupon list",
        COUPON_NAME: "Coupon name",
        COUPON_CODE: "Coupon code",
        DISABLE_ALL: "Disable all",
        TYPE: "Type",
        SELECTED_RECORDS_COUNT: "Selected Coupons:",
      },
      FETCH_COUPON: {
        FETCH_SELECTED_COUPONS: "Fetch selected coupons",
      },
      EDIT_COUPON: {
        COUPON_NAME: "Coupon name",
        COUPON_CODE: "Coupon code",
        DISCOUNT: "Discount",
        DAYS_TO_TRIAL: "Days to Trial",
        MAX_USAGE_COUNT: "Max Usage Count",
        SUBSCRIPTION: "Subscription",
        PERCENT_DISCOUNT: "Discount Percentage",
        DIRECT_DISCOUNT: "Direct Discount",
        TRIAL: "Trial Period",
      },
      DISABLE_COUPON: {
        COUPON_DISABLE: "Disable coupon",
        ARE_YOU_SURE_TO_DISABLE_THIS_COUPON:
          "Are you sure you want to disable this coupon?",
        COUPON_IS_BEING_DISABLED: "Desabling coupon",
      },
      DISABLE_COUPONS: {
        COUPONS_DISABLE: "Disable coupons",
        ARE_YOU_SURE_TO_DISABLE_SELECTED_COUPONS:
          "Are you sure you want to disable these coupons?",
        COUPONS_ARE_BEING_DISABLED: "Disabling Coupons",
      },
      PAYMENTS: {
        TRIAL: "Trial period",
        CREDIT_CARD: "Credit card",
        BANK_SLIP: "Bank slip",
        FAILED: "Failed",
        PROCESSED: "Processed",
        REQUESTED: "Requested",
        PAYMENTS_LIST: "Payments list",
        USERNAME: "Username",
        SUBSCRIPTION_NAME: "Subscription name",
        STATUS: "Status",
        PRICE: "Price",
        CREATED: "Creation date",
        TYPE: "Type",
      },
      FETCH_PAYMENTS_LIST: {
        FETCH_PAYMENT: "Fetch Payment",
      },
      VIEW_PAYMENT: {
        PAYMENT: "Payment",
        USER_SID: "User SID",
        SUBSCRIPTION_SID: "Subscription SID",
        COUPON_CODE_SID: "Coupon code SID",
        USER_SUBSCRIPTION_SID: "User subscription SID",
        TYPE: "Type",
        STATUS: "Status",
        PRICE: "Price",
        PAID_INSTALLMENTS: "Paid installments",
        TOTAL_INSTALLMENTS: "Total installments",
        BOLETO_URL: "Billet URL",
        BOLETO_BAR_CODE: "Billet bar code",
        COMMENTS: "Comments",
        CREDIT_CARD_HASH: "Credit card hash",
        CREDIT_CARD_ID: "Credit card ID",
        SUBSCRIPTION_NAME: "Subscription name",
        COUPON_CODE_NAME: "Coupon name",
        USER_NAME: "Username",
      },
      SUBSCRIPTIONS: {
        SELECTED_RECORDS_COUNT: "Selected Subscriptions:",
        DISABLE_ALL: "Disable Selected",
        SUBSCRIPTIONS_LIST: "Subscriptions List",
        MONTHLY_PRICE: "Monthly Price",
        YEARLY_PRICE: "Yearly Price",
        NEW_SUBSCRIPTION: "New Subscription",
      },
      EDIT_SUBSCRIPTION: {
        SUBSCRIPTION: "Subscription",
        MONTHLY_PRICE: "Monthly Price",
        YEARLY_PRICE: "Yearly Price",
        KEYCLOAK_ROLES: "Keycloak Roles",
      },
      DISABLE_SUBSCRIPTION: {
        SUBSCRIPTION_DISABLE: "Disable Subscription",
        ARE_YOU_SURE_TO_DISABLE_THIS_SUBSCRIPTION:
          "Are you sure you want to disable this subscription?",
        SUBSCRIPTION_IS_DISABLING: "The subscription is being disabled.",
      },
      DISABLE_SUBSCRIPTIONS: {
        SUBSCRIPTIONS_DISABLE: "Disable Subscriptions",
        ARE_YOU_SURE_TO_DISABLE_SELECTED_SUBSCRIPTIONS:
          "Are you sure you want to disable the selected subscriptions?",
        SUBSCRIPTIONS_ARE_DISABLING: "Those subscriptions are being disabled.",
      },
    },
  },
};
