export type SortDirection = 'asc' | 'desc' | '';

export interface ISortState {
  column: string;
  direction: SortDirection;
}

export class SortState implements ISortState {
  column: string;
  direction: SortDirection;

  constructor(column: string = 'id', direction: SortDirection = 'asc') {
    this.column = column;
    this.direction = direction;
  }
}

export interface ISortView {
  sorting: SortState;
  ngOnInit(): void;
  sort(column: string): void;
}
